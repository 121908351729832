import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import { Button, Container } from 'hds-react';
import { navigate } from 'gatsby';
import Hero from '../../components/Hero';
import Image from '../../components/Image';
import FrontPageProductsList from '../../components/FrontPageProductsList';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Hero title="บริการให้คำปรึกษาและพัฒนาซอฟแวร์ทุกระดับ" text="ด้วยประสบการณ์กว่า 10 ปี เราให้บริการด้วยความมุ่งมั่น พร้อมใส่ใจในทุกบริการหลังการขาย ปัญหาของคุณจะได้รับการแก้ปัญหาที่นี่ ปรึกษาเราได้ฟรี" backgroundImageUrl="/images/homepage/home-bg.png" mdxType="Hero">
  <Button variant="primary" className="front-page-hero-button" role="link" onClick={() => {
        navigate('/contact');
      }} mdxType="Button">
    ติดต่อเรา anndream.com@gmail.com
  </Button>
    </Hero>
    <Container style={{
      margin: '0 auto var(--spacing-layout-l)'
    }} mdxType="Container">
   <FrontPageProductsList mdxType="FrontPageProductsList" />
  <Image size="M" src="/images/getting-started/seree-app-home.svg" alt="Seree ERP" viewable mdxType="Image" />
 <Image size="M" src="/images/services/mycustomer.svg" alt="Anndream Software Customers" style={{
        "display": "block",
        "width": "100%",
        "minWidth": "600px",
        "maxWidth": "915px",
        "margin": "0 auto"
      }} mdxType="Image" />
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      